.formContainer {
	input {
		background: white;
		border-radius: 5px;
		margin-right: 5px;
		border: 1px solid white;
		padding: 5px;
		width: clamp(150px, 20vw, 500px);
		padding-left: 10px;
		height: 20px;

		&::placeholder {
			color: grey;
		}
	}

	form {
		display: flex;
		align-items: stretch;
	}

	button {
		border: none;
		border-radius: 5px;
		background: rgb(26, 26, 26);
		color: white;
		text-transform: uppercase;
		padding: 5px 10px;
		font-weight: bold;
		height: 32px;
		cursor: pointer;
	}
}
