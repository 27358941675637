.albums {
	padding: 20px 40px;
	border-radius: 20px;
	background: white;
	box-shadow: -2px 4px 20px -10px black;

	@media (max-width: 600px) {
		padding: 20px 15px;
	}

	&__header {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 1;
		justify-content: center;
		text-align: center;
		margin-bottom: 2rem;
		padding-block: 15px;
		border-bottom: 2px grey solid;

		& > p {
			display: flex;
			align-items: center;
			gap: 5px;
			color: grey;
			cursor: pointer;
		}

		svg {
			height: 12px;
			cursor: pointer;
		}
	}

	//? LIST
	&.list {
		.albums__item {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			margin-top: 0.5rem;
			gap: 15px;
			align-items: center;

			@media (max-width: 600px) {
				grid-template-columns: 10% 40% 30% 20%;
			}
		}
	}

	//? GRID
	&.grid {
		.albums__content {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 2rem;

			@media (max-width: 600px) {
				grid-template-columns: 1fr 1fr;
			}
		}

		.albums__item {
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: 5px;
			margin-bottom: 2rem;

			p {
				margin: 0;
			}

			p:first-child {
				font-weight: bold;
			}
		}
	}

	button {
		background: transparent;
		border: none;
		font-size: 20px;
		color: red;
		cursor: pointer;
		margin-inline: 5px;
		transition: 0.2s;

		&:hover {
			transition: 0.2s;
			scale: 1.2;
		}

		&.albums__remove svg {
			color: black;
		}

		.fa-heart {
			color: #00000042;

			&.fa-inverse {
				color: red;
			}
		}
	}
}
