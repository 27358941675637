@font-face {
	font-family: "Montserrat", sans-serif;
	src: url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
}

body {
	margin: 0;
	box-sizing: border-box;
}

* {
	font-family: "Montserrat", sans-serif;
}
