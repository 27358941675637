.app {
	background: url("./img/background.png") no-repeat;
	min-height: 100vh;
	padding-top: 10rem;

	&__container {
		margin-inline: auto;
		width: clamp(350px, 80%, 1000px);
	}

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: calc(10px + 2vmin);
	}
	&__buttonsContainer {
		margin-block: 1rem;

		button {
			background: transparent;
			border: none;
			font-size: 25px;
			cursor: pointer;
			color: white;
		}
	}
}
